<template>
  <div>
    <div class="e-breadcrumb">考勤设置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
        <el-form ref="form" label-width="120px" style="margin-top: -10px">

            <el-form-item label="wifi名称:" required>
                <el-input size="small" v-model="wifiName" maxlength="64" show-word-limit style="width: 268px;"></el-input>
            </el-form-item>
            <el-form-item label="mac地址:" required>
              <el-input size="small" v-model="bssid" maxlength="64" show-word-limit style="width: 268px;"></el-input>
            </el-form-item>
        </el-form>
        <div class="card-footer" style="width: 398px;">
            <el-button size="small" type="primary" @click="saveWifi">确 定</el-button>
        </div>
    </div>
      </div>
    </div>
  </div>

</template>
<script>

    export default {
        data() {
            return {
              id:'',
              wifiName:'',
              bssid:'',
            }
        },
        async created() {
        this.getWifi()
        },
        methods: {
            async getWifi(){
              let res = await this.$get("/admin/getWifi");
              this.wifiName=res.item.wifiName
              this.bssid=res.item.bssid
              this.id=res.item.id
            },
            async saveWifi() {
                let data = {
                  id:this.id,
                  wifiName: this.wifiName,
                  bssid: this.bssid,
                };
                if (!this.wifiName) {
                    this.$message.error('请输入wifi名称!');
                    return
                }
                if (!this.bssid) {
                    this.$message.error("请输入mac地址!");
                    return
                }
                let res = await this.$post("/admin/saveWifi", data);
                if (res.code == 200) {
                    this.$message.success(res.message);
                }else {
                  this.$message.error(res.message);
                }
            }
        }
    }
</script>
<style>

</style>
